import React from 'react';
import {
  BooleanInput,
  Create,
  CreateProps,
  minValue,
  NumberInput,
  required,
  SimpleForm,
} from 'react-admin';

function ShopSettingsCreate(props: CreateProps) {
  return (
    <Create {...props}>
      <SimpleForm>
        <NumberInput label="Shop ID" source="shopId" />
        <NumberInput
          source="keepItemsLimitPeriodMonths"
          validate={[required(), minValue(1)]}
        />
        <NumberInput
          source="keepItemsLimitCount"
          validate={[required(), minValue(1)]}
        />
        <BooleanInput source="isSomethingEnabled" />
      </SimpleForm>
    </Create>
  );
}

export default ShopSettingsCreate;
