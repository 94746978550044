import ky from 'ky';
import { Auth, getAuth } from './authStorage';

const apiUrl = process.env.REACT_APP_RETURN_PORTAL_API_URL || 'http://localhost:3800';
const baseUrl = `${apiUrl}/admin`;

function getAuthHeaders(): Record<string, string | undefined> {
  const auth = getAuth();
  return auth ? { Authorization: `Bearer ${auth.accessToken}` } : {};
}

export function getByPath(path: string, query: Record<string, string | number> = {}): Promise<unknown> {
  return ky
    .get(`${baseUrl}${path}`, {
      searchParams: query,
      headers: getAuthHeaders(),
    })
    .json();
}

export function patchByPath(path: string, data: unknown): Promise<unknown> {
  return ky.patch(`${baseUrl}${path}`, { headers: getAuthHeaders(), json: data }).json();
}

export function postByPath(path: string, data: unknown): Promise<unknown> {
  return ky.post(`${baseUrl}${path}`, { headers: getAuthHeaders(), json: data }).json();
}

export function deleteByPath(path: string): Promise<unknown> {
  return ky.delete(`${baseUrl}${path}`, { headers: getAuthHeaders() }).json();
}

type LoginPayload = {
  username: string;
  password: string;
};

export function login(payload: LoginPayload): Promise<Auth> {
  return ky.post(`${baseUrl}/login`, { json: payload }).json();
}

export function logout(username: string): Promise<void> {
  return ky
    .post(`${baseUrl}/logout`, {
      json: { username },
      headers: getAuthHeaders(),
    })
    .json();
}
