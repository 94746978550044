import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Datagrid,
  ImageField,
  NumberFieldProps,
  ReferenceManyField,
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';
import { ShowProps } from 'react-admin';

const useStyles = makeStyles({
  image: { maxHeight: '60px' },
});

function OrderItemsShow(props: ShowProps) {
  const image = useStyles();

  return (
    <Show {...props}>
      <SimpleShowLayout resource="returns_items">
        <ReferenceManyField reference="returns_items" target="requestId" label="Items">
          <Datagrid>
            <TextField source="orderItemId" label="Order item ID" />
            <TextField source="name" label="Name" />
            <PriceField label="Price" />
            <TextField source="reason" label="Reason" />
            <ImageField classes={image} source="productImageUrl" label="Product image" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
}

function PriceField(props: NumberFieldProps) {
  const { record } = props;

  if (!record) {
    return null;
  }

  const price: number = record['price'] ?? 0;
  const currency: string = record['currency'];
  const value = `${price.toFixed(2)} ${currency}`;

  return <span>{value}</span>;
}

export default React.memo(OrderItemsShow);
