import React from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { getResources, MenuItemLink, MenuProps, ReduxState } from 'react-admin';
import LabelIcon from '@material-ui/icons/Label';

import SubMenu from './SubMenu';
import { makeStyles } from '@material-ui/core/styles';

function CustomMenu(props: MenuProps) {
  const resources = useSelector(getResources);
  const open = useSelector((state: ReduxState) => state.admin.ui.sidebarOpen);
  const classes = useStyles();

  const groupedResources = groupResourcesByNamePrefix(resources);
  const products = Object.keys(groupedResources);

  return (
    <div
      className={classNames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open,
      })}
    >
      {products.map((product) => (
        <SubMenu icon={<LabelIcon />} name={productLabels[product]} dense={false} isOpen handleToggle={() => {}}>
          {groupedResources[product]
            .filter((r) => r.hasList)
            .map((resource) => (
              // @ts-ignore
              <MenuItemLink
                key={resource.name}
                to={{
                  pathname: `/${resource.name}`,
                  state: { _scrollToTop: true },
                }}
                leftIcon={resource.options?.icon}
                primaryText={resource.options?.label || resource.name}
                dense={props.dense}
              />
            ))}
        </SubMenu>
      ))}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    width: 200,
  },
  closed: {
    width: 55,
  },
}));

const productLabels: Record<string, string> = {
  returns: 'Returns',
  claims: 'Claims',
  tracking: 'Tracking',
};

type Resource = {
  name: string;
};

type GroupedResources = Record<string, Array<any>>;

function groupResourcesByNamePrefix(resources: Array<Resource>): GroupedResources {
  return resources.reduce<GroupedResources>((acc: GroupedResources, res: Resource) => {
    const prefix = getResourceNamePrefix(res.name);

    return {
      ...acc,
      [prefix]: [...(acc[prefix] || []), res],
    };
  }, {});
}

function getResourceNamePrefix(name: string): string {
  const [prefix] = name.split('_');
  return prefix;
}

export default React.memo(CustomMenu);
