export type Auth = {
  username: string;
  accessToken: string;
};

export function setAuth(auth: Auth) {
  sessionStorage.setItem('auth', JSON.stringify(auth));
}

export function clearAuth() {
  sessionStorage.setItem('auth', '');
}

export function getAuth(): Auth | undefined {
  try {
    const strAuth = sessionStorage.getItem('auth');
    return JSON.parse(strAuth || '');
  } catch (e) {
    return undefined;
  }
}
