import React from 'react';
import { Admin, Resource } from 'react-admin';

import apiDataProvider from './providers/apiDataProvider';
import authProvider from './providers/authProvider';
import LoginPage from './components/LoginPage';
import ReturnsList from './components/ReturnsList';
import ShopSettingsList from './components/ShopSettingsList';
import ShopSettingsEdit from './components/ShopSettingsEdit';
import ShopSettingsCreate from './components/ShopSettingsCreate';
import CustomLayout from './components/layout/CustomLayout';

import SettingsIcon from '@material-ui/icons/Settings';
import ReorderIcon from '@material-ui/icons/Reorder';

function App() {
  return (
    <Admin
      loginPage={LoginPage}
      layout={CustomLayout}
      dataProvider={apiDataProvider}
      authProvider={authProvider}
      disableTelemetry
    >
      <Resource
        name="returns_requests"
        list={ReturnsList}
        options={{ label: 'Return requests', icon: <ReorderIcon /> }}
      />
      <Resource name="returns_items" options={{ label: 'Items' }} />
      <Resource
        name="returns_shopSettings"
        options={{ label: 'Shop settings', icon: <SettingsIcon /> }}
        list={ShopSettingsList}
        edit={ShopSettingsEdit}
        create={ShopSettingsCreate}
      />
      <Resource
        name="claims_shopSettings"
        list={ReturnsList}
        options={{ label: 'Shop settings', icon: <SettingsIcon /> }}
      />
      <Resource
        name="tracking_shopSettings"
        list={ReturnsList}
        options={{ label: 'Shop settings', icon: <SettingsIcon /> }}
      />
    </Admin>
  );
}

export default App;
