import {
  CreateParams,
  DataProvider,
  DeleteParams,
  UpdateParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
} from 'react-admin';

import { deleteByPath, getByPath, patchByPath, postByPath } from './api';

const pathByResource: Record<string, string> = {
  returns_requests: '/returns',
  returns_items: '/return-items',
  returns_shopSettings: '/shop-settings',
};

const provider = {
  getList: (resource: string, params: GetListParams) => {
    const query = getListQuery(params);
    const path = pathByResource[resource];

    return getByPath(path, query);
  },

  getOne: (resource: string, params: GetOneParams) => {
    const path = pathByResource[resource];

    return getByPath(`${path}/${params.id}`).then((data) => ({ data }));
  },

  getMany: (resource: string, params: GetManyParams) => {
    return Promise.resolve({
      data: [],
    });
  },

  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    if (resource === 'returns_items') {
      return getByPath(`/returns/${params.id}/items`).then((data) => ({
        data,
        total: (data as any).length,
      }));
    }

    return Promise.resolve({ data: [], total: 0 });
  },

  create: (resource: string, params: CreateParams) => {
    const path = pathByResource[resource];

    return postByPath(path, params.data).then((data) => ({
      data,
    }));
  },

  delete: (resource: string, params: DeleteParams) => {
    const path = pathByResource[resource];

    return deleteByPath(`${path}/${params.id}`).then((data) => ({
      data,
    }));
  },

  deleteMany: () => Promise.resolve({ data: [] }),

  update: (resource: string, params: UpdateParams) => {
    const path = pathByResource[resource];

    return patchByPath(`${path}/${params.id}`, params.data).then((data) => ({
      data,
    }));
  },
  updateMany: () => Promise.resolve({ data: [] }),
} as DataProvider;

function getListQuery(params: GetListParams): Record<string, string | number> {
  const { perPage, page } = params.pagination;
  const {
    sort,
    filter: { startDate, endDate },
  } = params;

  const query: Record<string, string | number> = {
    offset: (page - 1) * perPage,
    limit: perPage,
    sort: `${sort.field}:${sort.order}`,
  };

  if (startDate) {
    query.startDate = startDate;
  }

  if (endDate) {
    query.endDate = endDate;
  }

  return query;
}

export default provider;
