import React from 'react';
import { Layout, LayoutProps } from 'react-admin';

import CustomMenu from './CustomMenu';

function CustomLayout(props: LayoutProps) {
  return <Layout {...props} menu={CustomMenu} />;
}

export default React.memo(CustomLayout);
