import React from 'react';
import { Datagrid, DateField, List, TextField } from 'react-admin';
import { ListProps } from 'ra-ui-materialui/lib/types';
import OrderItemsShow from './OrderItemsShow';
import ReturnsListSide from './ReturnsListSide';

function ReturnsList(props: ListProps) {
  return (
    <List
      {...props}
      aside={<ReturnsListSide />}
      bulkActionButtons={false}
      sort={{ field: 'id', order: 'DESC' }}
    >
      <Datagrid rowClick="expand" expand={<OrderItemsShow />}>
        <TextField source="id" label="ID" />
        <TextField source="orderId" label="Order ID" />
        <TextField source="shopId" label="Shop ID" />
        <TextField source="workflowType" label="Workflow" />
        <TextField source="returnPortalHash" label="Return Portal" />
        <DateField source="createdAt" label="Date" />
      </Datagrid>
    </List>
  );
}

export default ReturnsList;
