import React from 'react';
import { FilterList, FilterListItem } from 'react-admin';
import { Card as MuiCard, CardContent, withStyles } from '@material-ui/core';
import { endOfYesterday, startOfWeek, startOfMonth } from 'date-fns';

import AccessTimeIcon from '@material-ui/icons/AccessTime';

const Card = withStyles(() => ({
  root: {
    order: -1,
    width: '15em',
    marginRight: '1em',
  },
}))(MuiCard);

function ReturnsListSide() {
  return (
    <Card>
      <CardContent>
        <FilterList label="Date" icon={<AccessTimeIcon />}>
          <FilterListItem
            label="Today"
            value={{
              startDate: endOfYesterday().toISOString(),
              endDate: undefined,
            }}
          />
          <FilterListItem
            label="This week"
            value={{
              startDate: startOfWeek(new Date()).toISOString(),
              endDate: undefined,
            }}
          />
          <FilterListItem
            label="This month"
            value={{
              startDate: startOfMonth(new Date()).toISOString(),
              endDate: undefined,
            }}
          />
        </FilterList>
      </CardContent>
    </Card>
  );
}

export default React.memo(ReturnsListSide);
