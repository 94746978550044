import React from 'react';
import {
  ListProps,
  BooleanField,
  Datagrid,
  List,
  TextField,
  EditButton,
  CreateButton,
} from 'react-admin';

function ShopSettingsList(props: ListProps) {
  return (
    <List {...props} bulkActionButtons={false}>
      <Datagrid>
        <TextField source="shopId" label="Shop ID" />
        <TextField
          source="keepItemsLimitPeriodMonths"
          label="Keep items limit period"
        />
        <TextField
          source="keepItemsLimitCount"
          label="Keep items limit count"
        />
        <BooleanField source="isSomethingEnabled" label="Something enabled" />
        <CreateButton />
        <EditButton />
      </Datagrid>
    </List>
  );
}

export default ShopSettingsList;
