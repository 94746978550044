import {
  EditProps,
  Edit,
  SimpleForm,
  NumberInput,
  BooleanInput,
  required,
  minValue,
} from 'react-admin';
import React from 'react';

function ShopSettingsEdit(props: EditProps) {
  return (
    <Edit {...props}>
      <SimpleForm>
        <NumberInput disabled label="Shop ID" source="shopId" />
        <NumberInput
          source="keepItemsLimitPeriodMonths"
          validate={[required(), minValue(1)]}
        />
        <NumberInput
          source="keepItemsLimitCount"
          validate={[required(), minValue(1)]}
        />
        <BooleanInput source="isSomethingEnabled" />
      </SimpleForm>
    </Edit>
  );
}

export default ShopSettingsEdit;
