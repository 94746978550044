import { AuthProvider } from 'react-admin';

import { login, logout } from './api';
import { setAuth, clearAuth, getAuth } from './authStorage';

const authProvider: AuthProvider = {
  login: (payload) => {
    return login(payload).then((auth) => setAuth(auth));
  },
  logout: async () => {
    const auth = getAuth();

    if (!auth) {
      return;
    }

    return logout(auth.username).then(() => clearAuth());
  },

  checkAuth: async () => {
    if (!getAuth()) {
      throw Error('no session');
    }
  },
  checkError: async () => {},
  getPermissions: async (params) => {},
};

export default authProvider;
